import React from 'react';
import BasePage from '../basePage';
import Barbados1 from '../../images/pictures/holidays/cyprus07/p6290010_disp.jpg';
import Barbados2 from '../../images/pictures/holidays/cyprus07/p7010017_disp.jpg';
import Barbados3 from '../../images/pictures/holidays/cyprus07/p7010021_disp.jpg';
import Barbados4 from '../../images/pictures/holidays/cyprus07/p7010022_disp.jpg';
import Barbados5 from '../../images/pictures/holidays/cyprus07/p7010024_disp.jpg';
import Barbados6 from '../../images/pictures/holidays/cyprus07/p7010026_disp.jpg';
import Barbados7 from '../../images/pictures/holidays/cyprus07/p7010028_disp.jpg';
import Barbados8 from '../../images/pictures/holidays/cyprus07/p7030062_disp.jpg';
import Barbados9 from '../../images/pictures/holidays/cyprus07/p7050070_disp.jpg';
import Barbados10 from '../../images/pictures/holidays/cyprus07/p7050074_disp.jpg';
import Barbados11 from '../../images/pictures/holidays/cyprus07/p7050075_disp.jpg';
import Barbados12 from '../../images/pictures/holidays/cyprus07/p7050078_disp.jpg';
import Barbados13 from '../../images/pictures/holidays/cyprus07/p7050081_disp.jpg';
import Barbados14 from '../../images/pictures/holidays/cyprus07/p7050099_disp.jpg';
import Barbados15 from '../../images/pictures/holidays/cyprus07/p7050103_disp.jpg';
import Barbados16 from '../../images/pictures/holidays/cyprus07/p7100111_disp.jpg';
import Barbados17 from '../../images/pictures/holidays/cyprus07/p7100113_disp.jpg';
import Barbados18 from '../../images/pictures/holidays/cyprus07/p7100123_disp.jpg';

const images = [
  Barbados1, Barbados2, Barbados3, Barbados4, Barbados5, Barbados6, Barbados7, Barbados8,
  Barbados9, Barbados10, Barbados11, Barbados12, Barbados13, Barbados14, Barbados15, Barbados16,
  Barbados17, Barbados18
];

function Cyprus2007(props) {
  return <BasePage {...props} images={images} />
}

export default Cyprus2007;