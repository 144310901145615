import React from 'react';
import BasePage from '../basePage';
import Daniel1 from '../../images/pictures/daniel/p1250014_disp.jpg';
import Daniel2 from '../../images/pictures/daniel/p2090039_disp.jpg';
import Daniel3 from '../../images/pictures/daniel/p3310052_disp.jpg';
import Daniel4 from '../../images/pictures/daniel/p4170065_disp.jpg';
import Daniel5 from '../../images/pictures/daniel/p7210024_disp.jpg';
import Daniel6 from '../../images/pictures/daniel/p8140029_disp.jpg';
import Daniel7 from '../../images/pictures/daniel/p9060043_disp.jpg';
import Daniel8 from '../../images/pictures/daniel/p9060044_disp.jpg';
import Daniel9 from '../../images/pictures/daniel/pa130002_disp.jpg';
import Daniel10 from '../../images/pictures/daniel/pc050052_disp.jpg';

const images = [
  Daniel1, Daniel2, Daniel3, Daniel4, Daniel5,
  Daniel6, Daniel7, Daniel8, Daniel9, Daniel10
];

function Daniel(props) {
  return <BasePage {...props} images={images} />
}

export default Daniel;