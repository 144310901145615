import React from 'react';
import BasePage from '../basePage';
import During1 from '../../images/pictures/wedding/during/atlast_disp.jpg';
import During2 from '../../images/pictures/wedding/during/certificate_disp.jpg';
import During3 from '../../images/pictures/wedding/during/congratulations_disp.jpg';
import During4 from '../../images/pictures/wedding/during/gottabeatleasthalftwonow_disp.jpg';
import During5 from '../../images/pictures/wedding/during/janandian1_disp.jpg';
import During6 from '../../images/pictures/wedding/during/janandian2_disp.jpg';
import During7 from '../../images/pictures/wedding/during/janandian3_disp.jpg';
import During8 from '../../images/pictures/wedding/during/janandian4_disp.jpg';
import During9 from '../../images/pictures/wedding/during/janandian5_disp.jpg';
import During10 from '../../images/pictures/wedding/during/janandian6_disp.jpg';
import During11 from '../../images/pictures/wedding/during/johnsigningregister_disp.jpg';
import During12 from '../../images/pictures/wedding/during/signmylifeaway_disp.jpg';

const images = [
  During1, During2, During3, During4, During5, During6, During7, During8,
  During9, During10, During11, During12
];

function During(props) {
  return <BasePage {...props} images={images} />
}

export default During;