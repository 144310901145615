import React, { Component } from 'react';
import Navbar from './components/navbar';
import Body from './components/body';

class App extends Component {
  
  render() {
    return (
      <div className="mainDiv">
        <Navbar />
        <Body content={this.props.children} />
      </div>
    );
  }
}

export default App;
