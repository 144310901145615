import React from 'react';
import pageNotFoundImg from '../images/404.png';

const pageNotFound = () => {
    return (
        <div className="pictureDiv">
            <img src={pageNotFoundImg} style={{width: 800 }} title="Page Not Found" alt="Page Not Found" />
        </div>
    );
};

export default pageNotFound;