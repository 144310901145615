import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class LightBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: this.props.photoIndex
    };
  }

  toggleLightBox = (toggle, photoIndex) => {
    this.props.toggleLightBox(toggle, photoIndex);
  }

  render() {
    const { photoIndex } = this.state;
    return (
      <Lightbox
        mainSrc={this.props.disps[photoIndex]}
        nextSrc={this.props.disps[(photoIndex + 1) % this.props.disps.length]}
        prevSrc={this.props.disps[(photoIndex + this.props.disps.length - 1) % this.props.disps.length]}
        onCloseRequest={() => this.props.toggleLightBox(false, 0)}
        onMovePrevRequest={() =>
          this.setState({
            photoIndex: (photoIndex + this.props.disps.length - 1) % this.props.disps.length
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            photoIndex: (photoIndex + 1) % this.props.disps.length
          })
        }
      />
    );
  }
}

export default LightBox;