import React, { Fragment, useState } from 'react';
import Button from 'react-bootstrap/button';
import { Letters } from './Letters';
import { Numbers } from './Numbers';
import { Timer } from './Timer';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

function Countdown(props) {
    const [lettersSelected, toggleLettersSelected] = useState(true);
    const [timerActive, setTimerActive] = useState(false);

    return <Fragment>
        <div className="countdown outerDiv">
            <div className="leftDiv">
                <ButtonGroup aria-label="Basic example">
                    <Button className={lettersSelected ? "lettersGame gameSelected" : "lettersGame gameUnselected"} 
                        disabled={lettersSelected} onClick={() => toggleLettersSelected(!lettersSelected)} 
                        title="Play a letters game.">Letters</Button>
                    <Button className={!lettersSelected ? "lettersGame gameSelected" : "lettersGame gameUnselected"} 
                        disabled={!lettersSelected} onClick={() => toggleLettersSelected(!lettersSelected)} 
                        title="Play a numbers game.">Numbers</Button>
                </ButtonGroup>
            </div>
            <div className="middleDiv">
                {lettersSelected ? <Letters timerActive={timerActive} /> : <Numbers timerActive={timerActive} />}
            </div>
            <div className="rightDiv">
                <Timer timerActive={timerActive} setTimerActive={setTimerActive} />
            </div>
        </div>
    </Fragment>
}

export { Countdown };