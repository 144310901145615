import React, { useState, Fragment } from 'react';
import { alertActions } from '../../../actions';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import { useFirestore } from 'react-redux-firebase';
import { AnswerBox } from './AnswerBox';
import AddAnswer from '../modals/AddAnswer';

function Quiz(props) {
    // const [validated, setValidated] = useState(false);
    const [showAddAnswer, setShowAddAnswer] = useState(false);
    const [answer1, setAnswer1] = useState(null);
    const [answer1Correct, setAnswer1Correct] = useState(false);
    const [answer2, setAnswer2] = useState(null);
    const [answer2Correct, setAnswer2Correct] = useState(false);
    const [answer3, setAnswer3] = useState(null);
    const [answer3Correct, setAnswer3Correct] = useState(false);
    const [answer4, setAnswer4] = useState(null);
    const [answer4Correct, setAnswer4Correct] = useState(false);
    const [answerIndex, setAnswerIndex] = useState(0);
    // const firestore = useFirestore();

    // const handleSubmit = (event) => {
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //         //toast.error("Errors!");
    //     } else {
    //         return firestore
    //             .collection('jiras')
    //             .add({
    //                 sprintid: Number(event.target.elements[0].value),
    //                 number: event.target.elements[1].value,
    //                 requiresestimate: event.target.elements[2].checked,
    //                 estimate: event.target.elements[2].checked ? event.target.elements[3].value : 0,
    //                 details: event.target.elements[2].checked ? event.target.elements[4].value : event.target.elements[3].value,
    //                 stage: event.target.elements[2].checked ? event.target.elements[5].value : event.target.elements[4].value,
    //                 codestage: event.target.elements[2].checked ? event.target.elements[6].value : event.target.elements[5].value,
    //                 stp: event.target.elements[2].checked ? event.target.elements[7].checked : event.target.elements[6].checked,
    //                 stg: event.target.elements[2].checked ? event.target.elements[8].checked : event.target.elements[7].checked,
    //                 cs: event.target.elements[2].checked ? event.target.elements[9].checked : event.target.elements[8].checked,
    //                 tours: event.target.elements[2].checked ? event.target.elements[10].checked : event.target.elements[9].checked,
    //                 timebooked: 0
    //             });
    //     }

    //     setValidated(true);
    // };

    function showAddAnswerBox(answerIndex) {
        setAnswerIndex(answerIndex);
        setShowAddAnswer(true);
    }

    function hideAddAnswer() {
        setShowAddAnswer(false);
    }

    function saveAnswer(elements) {

        hideAddAnswer();
        switch (answerIndex) {
            case 2:
                setAnswer2(elements[0].value);
                setAnswer2Correct(elements[1].checked);
                if (elements[1].checked) {                    
                    setSpecificAnswer(2);
                }

                break;
            case 3:
                setAnswer3(elements[0].value);
                setAnswer3Correct(elements[1].checked);
                if (elements[1].checked) {
                    setSpecificAnswer(3);
                }

                break;
            case 4:
                setAnswer4(elements[0].value);
                setAnswer4Correct(elements[1].checked);
                if (elements[1].checked) {
                    setSpecificAnswer(4);
                }

                break;
            default:
                setAnswer1(elements[0].value);
                setAnswer1Correct(elements[1].checked);
                if (elements[1].checked) {
                    setSpecificAnswer(1);
                }

                break;
        }        
    }

    function showSpecificAnswer() {
        switch(answerIndex) {
            case 2:
                return answer2;
            case 3:
                return answer3;
            case 4:
                return answer4;
            default:
                return answer1;
        }
    }

    function correctAnswer() {
        switch(answerIndex) {
            case 2:
                return answer2Correct;
            case 3:
                return answer3Correct;
            case 4:
                return answer4Correct;
            default:
                return answer1Correct;
        }
    }

    function setSpecificAnswer(index) {
        switch(index) {
            case 2:
                setAnswer2Correct(true);
                setAnswer1Correct(false);
                setAnswer3Correct(false);
                setAnswer4Correct(false);
                break;
            case 3:
                setAnswer3Correct(true);
                setAnswer1Correct(false);
                setAnswer2Correct(false);
                setAnswer4Correct(false);
                break;
            case 4:
                setAnswer4Correct(true);
                setAnswer1Correct(false);
                setAnswer2Correct(false);
                setAnswer3Correct(false);
                break;
            default:
                setAnswer1Correct(true);
                setAnswer2Correct(false);
                setAnswer3Correct(false);
                setAnswer4Correct(false);
                break;
        }
        
    }

    return <Fragment>
        
        <Form noValidate /* validated={validated} */ /*onSubmit={(e) => handleSubmit(e)}*/ className="answerForm">
            <Form.Group as={Row} controlId="validationCustom01">
                <Col>
                    <InputGroup sm={10} className="questionBox">
                        <Form.Control required type="text" placeholder="Enter question" autoComplete="off" className="question" />
                        <Form.Control.Feedback type="invalid">Please enter a question.</Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Form.Group>

            <div className="answers">
                <div className="answersLeft">
                    <AnswerBox backgroundColor="#D8EE4E" onClick={() => showAddAnswerBox(1)} answer={answer1} 
                        correctAnswer={answer1Correct} answerIndex={answerIndex} setSpecificAnswer={(index) => setSpecificAnswer(1)} />
                    <AnswerBox backgroundColor="#8BB2F7" onClick={() => showAddAnswerBox(2)} answer={answer2} 
                        correctAnswer={answer2Correct} answerIndex={answerIndex} setSpecificAnswer={(index) => setSpecificAnswer(2)} />
                </div>
                <div className="answersRight">
                    <AnswerBox backgroundColor="#9AD680" onClick={() => showAddAnswerBox(3)} answer={answer3} 
                        correctAnswer={answer3Correct} answerIndex={answerIndex} setSpecificAnswer={(index) => setSpecificAnswer(3)} />
                    <AnswerBox backgroundColor="#A8A9A9" onClick={() => showAddAnswerBox(4)} answer={answer4} 
                        correctAnswer={answer4Correct} answerIndex={answerIndex} setSpecificAnswer={(index) => setSpecificAnswer(4)} />
                </div>
            </div>
            {/* <Button type="submit" variant="danger">Add Jira to new sprint</Button> */}
        </Form>
        <AddAnswer show={showAddAnswer} onHide={hideAddAnswer} saveAnswer={saveAnswer} answerIndex={answerIndex} answer={showSpecificAnswer()}
            correctAnswer={correctAnswer()} />
    </Fragment>
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedSprint = connect(mapState, actionCreators)(Quiz);
export { connectedSprint as Quiz };