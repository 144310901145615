import React from 'react';

const Development = (props) =>
  <section className="content-wrapper clear-fix">
    <h2>Development CodePieces</h2>
    <br />
    <fieldset>
      <h4>Web Page Code</h4>
      <h4>Javascript</h4>
    </fieldset>
  </section>;

export default Development;