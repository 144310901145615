import React from 'react';
import BasePage from '../basePage';
import Extras1 from '../../images/pictures/wedding/extras/afewofus1_disp.jpg';
import Extras2 from '../../images/pictures/wedding/extras/afewofus2_disp.jpg';
import Extras3 from '../../images/pictures/wedding/extras/carryingme_disp.jpg';
import Extras4 from '../../images/pictures/wedding/extras/carryingme1_disp.jpg';
import Extras5 from '../../images/pictures/wedding/extras/carryingme2_disp.jpg';
import Extras6 from '../../images/pictures/wedding/extras/carryingme3_disp.jpg';
import Extras7 from '../../images/pictures/wedding/extras/family1_disp.jpg';
import Extras8 from '../../images/pictures/wedding/extras/family2_disp.jpg';
import Extras9 from '../../images/pictures/wedding/extras/family3_disp.jpg';
import Extras10 from '../../images/pictures/wedding/extras/family4_disp.jpg';
import Extras11 from '../../images/pictures/wedding/extras/familyandjayne1_disp.jpg';
import Extras12 from '../../images/pictures/wedding/extras/familyandjayne2_disp.jpg';
import Extras13 from '../../images/pictures/wedding/extras/familyandjayne3_disp.jpg';
import Extras14 from '../../images/pictures/wedding/extras/horseshoehandover1_disp.jpg';
import Extras15 from '../../images/pictures/wedding/extras/horseshoehandover2_disp.jpg';
import Extras16 from '../../images/pictures/wedding/extras/horseshoehandover3_disp.jpg';
import Extras17 from '../../images/pictures/wedding/extras/janrunning1_disp.jpg';
import Extras18 from '../../images/pictures/wedding/extras/janrunning2_disp.jpg';
import Extras19 from '../../images/pictures/wedding/extras/jansshoes_disp.jpg';
import Extras20 from '../../images/pictures/wedding/extras/kyle1_disp.jpg';
import Extras21 from '../../images/pictures/wedding/extras/resevoirdogsstyley1_disp.jpg';
import Extras22 from '../../images/pictures/wedding/extras/theboys1_disp.jpg';
import Extras23 from '../../images/pictures/wedding/extras/theboys2_disp.jpg';
import Extras24 from '../../images/pictures/wedding/extras/us1_disp.jpg';
import Extras25 from '../../images/pictures/wedding/extras/us2_disp.jpg';
import Extras26 from '../../images/pictures/wedding/extras/us3_disp.jpg';
import Extras27 from '../../images/pictures/wedding/extras/us4_disp.jpg';
import Extras28 from '../../images/pictures/wedding/extras/us5_disp.jpg';
import Extras29 from '../../images/pictures/wedding/extras/us6_disp.jpg';
import Extras30 from '../../images/pictures/wedding/extras/us7_disp.jpg';
import Extras31 from '../../images/pictures/wedding/extras/us8_disp.jpg';
import Extras32 from '../../images/pictures/wedding/extras/us9_disp.jpg';
import Extras33 from '../../images/pictures/wedding/extras/usandjansmum1_disp.jpg';
import Extras34 from '../../images/pictures/wedding/extras/usandjansmum2_disp.jpg';
import Extras35 from '../../images/pictures/wedding/extras/usrunning1_disp.jpg';
import Extras36 from '../../images/pictures/wedding/extras/usrunning2_disp.jpg';

const images = [
  Extras1, Extras2, Extras3, Extras4, Extras5, Extras6, Extras7, Extras8,
  Extras9, Extras10, Extras11, Extras12, Extras13, Extras14, Extras15,
  Extras16, Extras17, Extras18, Extras19, Extras20, Extras21,
  Extras22, Extras23, Extras24, Extras25, Extras26, Extras27, Extras28,
  Extras29, Extras30, Extras31, Extras32, Extras33, Extras34, Extras35, Extras36
];

function Extras(props) {
  return <BasePage {...props} images={images} />
}

export default Extras;