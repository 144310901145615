import React, { useState, useEffect } from 'react';
import LightBox from './lightbox';

function BasePage(props) {
    const [disps, setDisps] = useState(null);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setDisps(props.images);
    },
        [props.images, setDisps]
    );

    function toggleLightBox(toggle, photoIndex) {
        setIsOpen(toggle);
        setPhotoIndex(photoIndex);
    }

    return (
        <div className="pictureDiv">
            <fieldset><legend>{props.content}</legend>
                <div id="mainContent">
                    <div id="container">
                        <div className="clear">
                            {props.images.map((image, index) => {
                                return <img src={image} alt="" key={index}
                                    onClick={() => toggleLightBox(true, index)} />
                            })}

                            {isOpen &&
                                <LightBox disps={disps} photoIndex={photoIndex}
                                    isOpen={isOpen} toggleLightBox={toggleLightBox} />
                            }
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    );
}

export default BasePage;
