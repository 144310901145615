import React from 'react';
import BasePage from '../basePage';
import OtherAircraft1 from '../../images/pictures/aircraft/other/a_disp.jpg';
import OtherAircraft2 from '../../images/pictures/aircraft/other/b_disp.jpg';
import OtherAircraft3 from '../../images/pictures/aircraft/other/c_disp.jpg';
import OtherAircraft4 from '../../images/pictures/aircraft/other/d_disp.jpg';
import OtherAircraft5 from '../../images/pictures/aircraft/other/e_disp.jpg';
import OtherAircraft6 from '../../images/pictures/aircraft/other/f_disp.jpg';

const images = [
  OtherAircraft1, OtherAircraft2, OtherAircraft3, OtherAircraft4, OtherAircraft5, OtherAircraft6
];

function Tornado(props) {
  return <BasePage {...props} images={images} />
}

export default Tornado;