import React, { useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Button from 'react-bootstrap/button';
import Player from '../Music';
import Music from '../../../sounds/countdown-clock-only.mp3';

function Timer(props) {
    const [ballCount, setBallCount] = useState(0);
    const [secondsRemaining] = useState(30);
    const [gameRunning, setGameRunning] = useState(false);

    function toggleGame() {
        setGameRunning(!gameRunning);
        props.setTimerActive(!props.timerActive);
    }

    function resetGame() {
        setGameRunning(false);
        props.setTimerActive(false);
    }

    function start() {
        toggleGame();
        newBallRoutine();
    }

    function newBallRoutine() {
        setBallCount(ballCount + 1);
    }

    return <div className="bingoTimer">
        <Player url={Music} start={start} timerStarted={gameRunning} />

        <div className="timer">
            <CountdownCircleTimer
                key={gameRunning}
                isPlaying={gameRunning}
                duration={secondsRemaining}
                colors={[
                    ['#004777', 0.33],
                    ['#F7B801', 0.33],
                    ['#A30000', 0.33],
                ]}
            >
                {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
            {/* <CountdownCircleTimer
                key={gameRunning}
                isPlaying={gameRunning}
                durationSeconds={secondsRemaining}
                renderTime={renderTime}
                colors={[
                    ['#004777', .33],
                    ['#F7B801', .33],
                    ['#A30000', .33]
                ]}
            /> */}
        </div>
        <Button className="stopGame" variant="danger" onClick={() => resetGame()}>
            Reset
        </Button>
    </div>
}

export { Timer };