export const settings = { maxBalls: 90, nicknames: {
    1: 'Kelly\'s Eye',
    2: 'One little duck',
    3: 'Cup of tea',
    4: 'Knock at the door',
    5: 'Man alive',
    6: 'Tom Mix',
    7: 'Lucky',
    8: 'Garden gate',
    9: 'Doctor\'s Orders',
    10: 'Boris\'s Den',
    11: 'Legs eleven',
    12: 'One dozen',
    13: 'Unlucky for some',
    14: 'The Lawnmower',
    15: 'Young and Keen',
    16: 'Never been kissed',
    17: 'Dancing Queen',
    18: 'Coming of Age',
    19: 'Goodbye Teens',
    20: 'One Score',
    21: 'Key of the Door',
    22: 'Two little ducks',
    23: 'The Lord is My Shepherd',
    24: 'Double dozen',
    25: 'Duck and dive',
    26: 'Two and six, half a crown',
    27: 'Duck and a crutch',
    28: 'In a state',
    29: 'Rise and Shine',
    30: 'Burlington Bertie',
    31: 'Get Up and Run',
    32: 'Buckle My Shoe',
    33: 'All the threes',
    34: 'Ask for More',
    35: 'Jump and Jive',
    36: 'Triple dozen',
    37: '',
    38: 'Christmas cake',
    39: 'Steps',
    40: 'Life Begins',
    41: '',
    42: '',
    43: 'Down on your knees',
    44: 'Droopy drawers',
    45: 'Halfway there',
    46: 'Up to tricks',
    47: '',
    48: 'Four Dozen',
    49: '',
    50: 'It\'s a bullseye!',
    51: '',
    52: 'Danny La Rue',
    53: 'Here comes Herbie',
    54: 'Man at the door',
    55: 'All the fives',
    56: 'Shotts Bus',
    57: 'Heinz Varieties',
    58: '',
    59: 'The Brighton Line',
    60: 'Grandmas getting frisky',
    61: '',
    62: 'Tickety-boo',
    63: '',
    64: 'Almost retired',
    65: 'Retirement age, Stop work',
    66: 'Clickety click',
    67: 'Stairway to Heaven',
    68: 'Pick a Mate',
    69: 'Anyway up',
    70: '',
    71: 'Bang on the Drum',
    72: 'Danny La Rue',
    73: 'Queen Bee',
    74: 'Hit the Floor',
    75: '',
    76: 'Trombones',
    77: 'Two little crutches',
    78: '39 more steps',
    79: '',
    80: 'Gandhi\'s Breakfast',
    81: 'Fat Lady with a walking stick',
    82: 'I\'m gonna get more right than you',
    83: 'Time for Tea',
    84: 'Seven dozen',
    85: 'Staying alive',
    86: 'Between the sticks',
    87: 'Torquay in Devon',
    88: 'Two Fat Ladies',
    89: 'Nearly there',
    90: 'Top of the shop'
} };