import React from 'react';
import BasePage from '../basePage';
import Barbados1 from '../../images/pictures/holidays/cyprus11/p00147_disp.jpg';
import Barbados2 from '../../images/pictures/holidays/cyprus11/p00148_disp.jpg';
import Barbados3 from '../../images/pictures/holidays/cyprus11/p00150_disp.jpg';
import Barbados4 from '../../images/pictures/holidays/cyprus11/p00151_disp.jpg';
import Barbados5 from '../../images/pictures/holidays/cyprus11/p0018_disp.jpg';
import Barbados6 from '../../images/pictures/holidays/cyprus11/p0019_disp.jpg';
import Barbados7 from '../../images/pictures/holidays/cyprus11/p0020_disp.jpg';
import Barbados8 from '../../images/pictures/holidays/cyprus11/p0021_disp.jpg';
import Barbados9 from '../../images/pictures/holidays/cyprus11/p0022_disp.jpg';
import Barbados10 from '../../images/pictures/holidays/cyprus11/p0024_disp.jpg';
import Barbados11 from '../../images/pictures/holidays/cyprus11/p0028_disp.jpg';
import Barbados12 from '../../images/pictures/holidays/cyprus11/p0030_disp.jpg';
import Barbados13 from '../../images/pictures/holidays/cyprus11/p0031_disp.jpg';
import Barbados14 from '../../images/pictures/holidays/cyprus11/p0032_disp.jpg';

const images = [
  Barbados1, Barbados2, Barbados3, Barbados4, Barbados5, Barbados6, Barbados7, Barbados8,
  Barbados9, Barbados10, Barbados11, Barbados12, Barbados13, Barbados14
];

function Cyprus2011(props) {
  return <BasePage {...props} images={images} />
}

export default Cyprus2011;