import React, { Fragment, useState } from 'react';
import Table from 'react-bootstrap/table';
import Button from 'react-bootstrap/button';
import Accordion from 'react-bootstrap/accordion';
import Card from 'react-bootstrap/card';
import { bingoActions } from '../../../actions';
import { connect } from 'react-redux';
import ImagePopUp from '../modals/imagePopup';
import { settings } from '../../../settings.js';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

var maxRange = settings.maxBalls;

function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

function Bingo(props) {
    const { storenumber, bingo, newGame } = props;
    const [ballCount, setBallCount] = useState(0);
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [secondsRemaining, setSecondsRemaining] = useState(5);
    const [sliderEnabled, toggleSlider] = useState(false);
    const [startEnabled, toggleStartEnabled] = useState(true);
    const [stopEnabled, toggleStopEnabled] = useState(false);
    const [gameRunning, setGameRunning] = useState(false);
    const [nextNumberEnabled, toggleNextNumberEnabled] = useState(false);
    const [newGameEnabled, toggleNewGameEnabled] = useState(true);
    const [newGameSelected, toggleNewGameSelected] = useState(false);

    if (bingo.bingonumbers === undefined) {
        props.retrieveAll();
    }

    var bingoCardComplete = bingo.bingonumbers !== undefined && bingo.bingonumbers.length === maxRange;
    var lastSelected = bingo.bingonumbers !== undefined ? bingo.bingonumbers[bingo.bingonumbers.length - 1] : null;

    const marks = {
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11,
        12: 12,
        13: 13,
        14: 14,
        15: 15
    };

    function createNewGame() {
        toggleNewGameSelected(!newGameSelected);
        newGame();
        setBallCount(0);
    }

    function toggleGame() {
        toggleSlider(!sliderEnabled);
        setGameRunning(!gameRunning);
        toggleStartEnabled(!startEnabled);
        toggleStopEnabled(!stopEnabled);
        toggleNextNumberEnabled(!nextNumberEnabled);
        toggleNewGameEnabled(!newGameEnabled);
    }

    function automateGame() {
        toggleGame();
        newBallRoutine();
    }

    function generateTable(bingonumbers) {
        let table = [];
        let row = [];
        range(1, maxRange).forEach(function (int, index) {
            var selected = bingonumbers !== undefined ? bingonumbers.some(v => v === int) : false;
            var lastSelected = bingonumbers !== undefined ? bingonumbers[bingonumbers.length - 1] === int : false;
            if (Number(int) % 10 === 0) {
                row.push(<td key={index} title={int} className={lastSelected
                    ? determineColour(bingonumbers[bingonumbers.length - 1]) + " blinking" : selected ? "selected" : ""}>{int}</td>);
                table.push(<tr key={"row_" + index}>{row}</tr>)
                row = [];
            } else {
                row.push(<td key={index} title={int} className={lastSelected
                    ? determineColour(bingonumbers[bingonumbers.length - 1]) + " blinking" : selected ? "selected" : ""}>{int}</td>);
            }
        });

        return table;
    }

    function newBallRoutine() {
        setBallCount(ballCount + 1);

        // Show Image
        setShowImagePopup(true);

        x().then(function () {
            // Store new number in local storage
            storenumber();

            // Hide Image
            setShowImagePopup(false);
        });
    }

    function determineColour(number) {
        if (number > 0 && number < 11) {
            return "red";
        } else if (number >= 11 && number < 21) {
            return "blue";
        } else if (number >= 21 && number < 31) {
            return "white";
        } else if (number >= 31 && number < 41) {
            return "green";
        } else if (number >= 41 && number < 51) {
            return "yellow";
        } else if (number >= 51 && number < 61) {
            return "purple";
        } else if (number >= 61 && number < 71) {
            return "cyan";
        } else if (number >= 71 && number < 81) {
            return "orange";
        } else {
            return "black";
        }
    }

    function x() {
        var promise = new Promise(function (resolve) {
            window.setTimeout(function () {
                resolve();
            }/*, 3000*/);
        });
        return promise;
    }

    return <Fragment>
        <div className="bingo outerDiv">
            <div className="leftDiv">
                <Button className="newGameBtn" disabled={!newGameEnabled} variant="dark" onClick={() => createNewGame()}
                    title="Beware! This will clear any active game.">New Game</Button>
            </div>
            <div className="middleDiv">
                <Table striped bordered hover className="numbersTable">
                    <tbody>
                        {generateTable(bingo.bingonumbers, bingo.lastNumber)}
                    </tbody>
                </Table>

                {lastSelected && settings.nicknames[lastSelected].length > 0
                    ? <div className="nickname">
                        <p>{lastSelected + ".  " + settings.nicknames[lastSelected]}</p>
                    </div>
                    : null}

                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            Automate Game
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Fragment>
                                <Card.Body style={{ marginLeft: 20, marginRight: 20, paddingBottom: 30 }}>
                                    Seconds
                                <Slider dots step={1} defaultValue={5} min={5} max={15}
                                        onAfterChange={(value) => setSecondsRemaining(value)} marks={marks}
                                        trackStyle={[{ backgroundColor: 'red' }, { backgroundColor: 'green' }]}
                                        handleStyle={[{ backgroundColor: 'blue' }, { backgroundColor: 'gray' }]}
                                        railStyle={{ backgroundColor: '#BBB6B6' }} disabled={sliderEnabled} />
                                </Card.Body>
                                <div className="autoSection">
                                    <Button className="startNewGame" variant="success" disabled={!startEnabled}
                                        onClick={() => automateGame()}>
                                        Start Game
                                    </Button>

                                    <div className="bingoTimer">
                                        <CountdownCircleTimer
                                            onComplete={() => {
                                                newBallRoutine()
                                                return [true, 1000]; // repeat animation in 1 second
                                            }}
                                            key={newGameSelected}
                                            isPlaying={gameRunning}
                                            duration={secondsRemaining}
                                            colors={[
                                                ['#004777', 0.33],
                                                ['#F7B801', 0.33],
                                                ['#A30000', 0.33],
                                            ]}
                                        >
                                            {({ remainingTime }) => remainingTime}
                                        </CountdownCircleTimer>
                                        {/* <CountdownCircleTimer
                                            onComplete={() => {
                                                newBallRoutine()
                                                return [true, 1000]; // repeat animation in 1 second
                                            }}
                                            key={newGameSelected}
                                            isPlaying={gameRunning}
                                            durationSeconds={secondsRemaining}
                                            renderTime={renderTime}
                                            colors={[
                                                ['#004777', .33],
                                                ['#F7B801', .33],
                                                ['#A30000']
                                            ]}
                                        /> */}
                                    </div>

                                    <Button className="stopGame" variant="danger" disabled={!stopEnabled}
                                        onClick={() => toggleGame()}>
                                        Stop Game
                                </Button>
                                </div>
                            </Fragment>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
            <br />
            <div className="rightDiv">
                <Button variant="info"
                    onClick={() => newBallRoutine()} disabled={bingoCardComplete || nextNumberEnabled}>Next Number</Button>
                <div id="ball" className={determineColour(lastSelected) + " relative notranslate"}>
                    {/* <div id="ballcount">{ballCount}</div> */}
                    <div className="content"><span>{lastSelected}</span>
                    </div>
                </div>
            </div>
            <ImagePopUp show={showImagePopup} onHide={() => setShowImagePopup(false)} />
        </div>
    </Fragment>
}

function mapState(state) {
    const { alert, bingo } = state;
    return { alert, bingo };
}

const actionCreators = {
    storenumber: bingoActions.storeNumber,
    retrieveAll: bingoActions.retrieveAll,
    newGame: bingoActions.clear
};

const connectedSprint = connect(mapState, actionCreators)(Bingo);
export { connectedSprint as Bingo };