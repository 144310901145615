import React from 'react';
import BasePage from '../basePage';
import Diet1 from '../../images/diet/Diet_Ingredients.jpg';
import Diet2 from '../../images/diet/Dietsheet.jpg';
import Diet3 from '../../images/diet/IMG_0191.jpg';
import Diet4 from '../../images/diet/IMG_0192.jpg';
import Diet5 from '../../images/diet/IMG_0193.jpg';
import Diet6 from '../../images/diet/IMG_0194.jpg';
import Diet7 from '../../images/diet/IMG_0195.jpg';
import Diet8 from '../../images/diet//IMG_0196.jpg';
import Diet9 from '../../images/diet/IMG_0197.jpg';
import Diet10 from '../../images/diet/IMG_0198.jpg';
import Diet11 from '../../images/diet/IMG_0199.jpg';
import Diet12 from '../../images/diet/IMG_0200.jpg';
import Diet13 from '../../images/diet/IMG_0201.jpg';
import Diet14 from '../../images/diet/IMG_0202.jpg';
import Diet15 from '../../images/diet/IMG_0203.jpg';
import Diet16 from '../../images/diet/IMG_0204.jpg';
import Diet17 from '../../images/diet/IMG_0205.jpg';
import Diet18 from '../../images/diet/IMG_0206.jpg';

const images = [
  Diet1, Diet2, Diet3, Diet4, Diet5, Diet6, Diet7, Diet8,
  Diet9, Diet10, Diet11, Diet12, Diet13, Diet14,
  Diet15, Diet16, Diet17, Diet18
];

function LowFodmapDetails(props) {
  return <BasePage {...props} images={images} />
}

export default LowFodmapDetails;