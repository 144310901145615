import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import PlayingCardImage from '../../../images/PlayingCard.jpg';
import ReactCardFlip from 'react-card-flip';
import PlayingCard25 from '../../../images/PlayingCard25.jpg';
import PlayingCard50 from '../../../images/PlayingCard50.jpg';
import PlayingCard75 from '../../../images/PlayingCard75.jpg';
import PlayingCard100 from '../../../images/PlayingCard100.jpg';
import PlayingCard1 from '../../../images/PlayingCard1.jpg';
import PlayingCard2 from '../../../images/PlayingCard2.jpg';
import PlayingCard3 from '../../../images/PlayingCard3.jpg';
import PlayingCard4 from '../../../images/PlayingCard4.jpg';
import PlayingCard5 from '../../../images/PlayingCard5.jpg';
import PlayingCard6 from '../../../images/PlayingCard6.jpg';
import PlayingCard7 from '../../../images/PlayingCard7.jpg';
import PlayingCard8 from '../../../images/PlayingCard8.jpg';
import PlayingCard9 from '../../../images/PlayingCard9.jpg';
import PlayingCard10 from '../../../images/PlayingCard10.jpg';

function PlayingCard(props) {
    const [isFlipped, setIsFlipped] = useState(false);

    function handleClick(cardId) {
        isFlipped ? props.setNumbersSelected(props.numbersSelected - 1) : props.setNumbersSelected(props.numbersSelected + 1);
        setIsFlipped(!isFlipped);
        props.setSelection([...props.selection, cardId]);
    }

    function determineFlippedCard(id) {
        switch(id) {
            case 1:
                return PlayingCard1;
            case 2:
                return PlayingCard2;
            case 3:
                return PlayingCard3;
            case 4:
                return PlayingCard4;
            case 5:
                return PlayingCard5;
            case 6:
                return PlayingCard6;
            case 7:
                return PlayingCard7;
            case 8:
                return PlayingCard8;
            case 9:
                return PlayingCard9;
            case 10:
                return PlayingCard10;
            case 25:
                return PlayingCard25;
            case 50:
                return PlayingCard50;
            case 75:
                return PlayingCard75;
            default:
                return PlayingCard100;
        }
    }

    return <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
        <Image src={PlayingCardImage} rounded className="playingCard" id={props.cardId} 
            onClick={props.numbersSelected === 6 ? null : () => handleClick(props.cardId)} />
        <Image src={determineFlippedCard(props.cardId)} rounded className="blankPlayingCard" id={props.cardId + "1"} />
    </ReactCardFlip>
}

export { PlayingCard };