import React, { useState, Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'react-toastify/dist/ReactToastify.css';

function AddAnswer(props) {
    const [validated, setValidated] = useState(false);

    function onSubmit(e) {
        const form = e.currentTarget;
        
        // We just want to populate the answers text box so don't submit here.
        e.preventDefault();
        e.stopPropagation();
        if (form.checkValidity() === false) {
        } else {
            props.saveAnswer(e.target.elements);
        }

        setValidated(true);
    }

    function elementChanged(e) {
        if (e.target.id === "validationCustom00") {
            setValidated(e.target.value !== "");
        }
    }

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.onHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Answer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={(e) => onSubmit(e)}>
                        <Form.Group as={Row} controlId="validationCustom00">
                            <Col>
                                <Form.Control as="textarea" rows="3" defaultValue={props.answer} placeholder="Enter your answer"
                                    required onChange={(e) => elementChanged(e)} />
                                <Form.Control.Feedback type="invalid">
                                    * Please enter an answer.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col>
                                <InputGroup sm={10}>
                                    <Form.Check type='switch' label="Is this the correct answer?" id={`default-estimate-required`}
                                    defaultChecked={props.correctAnswer} />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Button type="submit" variant="danger">
                            {props.answer === null ? "Add Answer" : "Update Answer"}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default AddAnswer;