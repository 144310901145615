import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'

class Home extends Component {

	render() {
		return (
			<Fragment>
				<div className="main-img">
					<div className="caption">
						<span className="border">WELCOME</span>
						<br /><br /><br />
						<span className="border"><FontAwesomeIcon icon={faAngleDoubleDown} /></span>
					</div>
				</div>
				<div style={{ color: "#777", backgroundColor: 'white', textAlign: 'justify', padding: 50 }}>
					<h3 style={{ textAlign: 'center' }}>How young?</h3>
				</div>
				<div className="middle-img">
					<div className="caption">
						<span className="border"><FontAwesomeIcon icon={faAngleDoubleUp} /></span>
						<br /><br />
						<span className="border"><FontAwesomeIcon icon={faAngleDoubleDown} /></span>
					</div>
				</div>
				<div style={{ position: "relative" }}>
					<div style={{ color: "#fff", backgroundColor: "#282E34", textAlign: "center", padding: 50 }}>
						<h4>The Mighty Fin</h4>
					</div>
				</div>
				<div className="bottom-img">
					<div className="caption">
						<span className="border"><FontAwesomeIcon icon={faAngleDoubleUp} /></span>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Home;
