import React, { Fragment, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { PlayingCard } from './PlayingCard';
import Button from 'react-bootstrap/button';
import { functions } from '../../../helpers';

function Numbers(props) {
    const allHighNumbers = [25, 50, 75, 100];
    const allLowNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [highCard1, setHighCard1] = useState(0);
    const [highCard2, setHighCard2] = useState(0);
    const [highCard3, setHighCard3] = useState(0);
    const [highCard4, setHighCard4] = useState(0);
    const [topRowSet, setTopRowSet] = useState(false);
    const [lowNumberGrid, setLowNumberGrid] = useState(null);
    const [lowNumberGridSet, setlowNumberGridSet] = useState(false);
    const [numbersSelected, setNumbersSelected] = useState(0);
    const [selection, setSelection] = useState([]);
    const [target, setTarget] = useState(0);

    function generateTopRow() {
        var numbers = allHighNumbers;
        var cardsSet = 1;
        while (cardsSet < 5) {
            const card = numbers[Math.floor(Math.random() * numbers.length)];
            var index = numbers.indexOf(card);
            if (cardsSet === 1) {
                setHighCard1(card);
                cardsSet++;
            } else if (cardsSet === 2) {
                setHighCard2(card);
                cardsSet++;
            } else if (cardsSet === 3) {
                setHighCard3(card);
                cardsSet++;
            } else {
                setHighCard4(card);
                setTopRowSet(true);
                cardsSet++;
            }
            numbers.splice(index, 1);
        }
    }

    function generateOtherRows() {
        var numbers = [...allLowNumbers];
        var cardsSet = 1;
        var cardList = [];
        // First ensure we have at least 1 of each of the low numbers in the rest of the cards
        while (cardsSet < 11) {
            const card = numbers[Math.floor(Math.random() * numbers.length)];
            var index = numbers.indexOf(card);
            cardList.push(card);
            numbers.splice(index, 1);
            cardsSet++;
        }

        // Now add in 18 more cards (as the grid is 7*4. i.e. 28 cards)
        numbers = [...allLowNumbers];
        cardsSet = 1;
        while (cardsSet < 19) {
            const card = numbers[Math.floor(Math.random() * numbers.length)];
            index = numbers.indexOf(card);
            cardList.push(card);
            cardsSet++;
        }

        // Now we have a list 1-10 in positions 1-10 and random for the next 18.
        // We now need to shuffle the who lost randomly.
        setLowNumberGrid(functions.shuffle(cardList));
        setlowNumberGridSet(true);
    }


    function clear() {
        setSelection([]);
        setNumbersSelected(0);
        setHighCard1(0);
        setHighCard2(0);
        setHighCard3(0);
        setHighCard4(0);
        setTopRowSet(false);
        setLowNumberGrid(null);
        setlowNumberGridSet(false);
        generateTopRow();
        generateOtherRows();
        setTarget(0);
    }

    if (!topRowSet) {
        generateTopRow();
    }

    if (!lowNumberGridSet) {
        generateOtherRows();
    }

    function GenerateLowerGrid() {
        let table = [];
        let row = [];
        lowNumberGrid.forEach(function (number, index) {
            if (index === 7 || index === 14 || index === 21) {
                table.push(<Row key={index}>{row}</Row>)
                row = [];
                row.push(<PlayingCard cardId={number} key={index} numbersSelected={numbersSelected} setNumbersSelected={setNumbersSelected}
                    selection={selection} setSelection={setSelection} />);
            } else if (index === 27) {
                row.push(<PlayingCard cardId={number} key={index} numbersSelected={numbersSelected} setNumbersSelected={setNumbersSelected}
                    selection={selection} setSelection={setSelection} />);
                table.push(<Row key={index}>{row}</Row>)
                row = [];
            } else {
                row.push(<PlayingCard cardId={number} key={index} numbersSelected={numbersSelected} setNumbersSelected={setNumbersSelected}
                    selection={selection} setSelection={setSelection} />);
            }
        });

        return table;
    }

    function generateTarget() {
        var target = Math.round(Math.random() * 999);
        setTarget(target);
    }

    return <Fragment>
        <Button className="startCountdown" variant="info" onClick={() => clear()} disabled={props.timerActive}>
            Clear
        </Button>
        <div>
            <Form.Control size="sm" type="text" className="numbers-box" disabled defaultValue={selection !== undefined ? selection[0] : null} />
            <Form.Control size="sm" type="text" className="numbers-box" disabled defaultValue={selection !== undefined ? selection[1] : null} />
            <Form.Control size="sm" type="text" className="numbers-box" disabled defaultValue={selection !== undefined ? selection[2] : null} />
            <Form.Control size="sm" type="text" className="numbers-box" disabled defaultValue={selection !== undefined ? selection[3] : null} />
            <Form.Control size="sm" type="text" className="numbers-box" disabled defaultValue={selection !== undefined ? selection[4] : null} />
            <Form.Control size="sm" type="text" className="numbers-box" disabled defaultValue={selection !== undefined ? selection[5] : null} />
        </div>
        {numbersSelected === 6 
            ? <Fragment>
                <Form.Control size="sm" type="text" className="numbers-box" disabled value={target} />
                <Button className="startCountdown" variant="info" onClick={() => generateTarget()} disabled={props.timerActive}>
                    Target
                </Button>
            </Fragment> 
            : <div className="game">
                <Container>
                    <Row>
                        <PlayingCard cardId={highCard1} numbersSelected={numbersSelected} setNumbersSelected={setNumbersSelected}
                            selection={selection} setSelection={setSelection} />
                        <PlayingCard cardId={highCard2} numbersSelected={numbersSelected} setNumbersSelected={setNumbersSelected}
                            selection={selection} setSelection={setSelection} />
                        <PlayingCard cardId={highCard3} numbersSelected={numbersSelected} setNumbersSelected={setNumbersSelected}
                            selection={selection} setSelection={setSelection} />
                        <PlayingCard cardId={highCard4} numbersSelected={numbersSelected} setNumbersSelected={setNumbersSelected}
                            selection={selection} setSelection={setSelection} />
                    </Row>
                    <Row>
                        {lowNumberGridSet ? GenerateLowerGrid() : null}
                    </Row>
                </Container>
        </div>}        
    </Fragment>
}

export { Numbers };