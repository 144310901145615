import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { getFirebase, getFirestore } from 'react-redux-firebase';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

const loggerMiddleware = createLogger();

export default function configureStore(initialState, history) {
  const middleware = [thunk.withExtraArgument({ getFirebase, getFirestore })]
  const createStoreWithMiddleware = composeWithDevTools(
    applyMiddleware(...middleware,
      loggerMiddleware),
    typeof window === 'object' &&
      typeof window.devToolsExtension !== 'undefined'
      ? () => window.__REDUX_DEVTOOLS_EXTENSION__
      : f => f
  )(createStore)
  const store = createStoreWithMiddleware(rootReducer)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}