import React, { useState, useEffect, Fragment } from 'react';
import Login from './login';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import { authActions } from '../actions';
import menu from '../images/menu_icon.png';

function NavBar(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.auth.uid) {
      setShow(false);
    }
  },
    [props.auth, setShow]
  );

  function handleClose() {
    setShow(false);
  }

  function handleShow() {
    setShow(true);
  }

  var buttonToShow = (props.auth.uid)
    ? <Button id="authBtn" variant="primary" onClick={props.logout}>Logout</Button>
    : <Button id="authBtn" variant="primary" onClick={handleShow}>Login</Button>;

  return (
    <Fragment>
      <nav className="nav">
      <div className="userSection">
          {props.profile.nickname ? <h6 className="inliner">Welcome {props.profile.nickname}</h6> : ""}
          {buttonToShow}
          <Login show={show} login={props.login}
            authError={props.authError}
            handleClose={handleClose} />
      </div>
        <div class="menuHover">
          <img src={menu} alt="MenuHover" />
          </div>
        <div className="table">
          <div className="tablecell">
            <div className="centered menu">
              <div>
                <LinkContainer to="/">
                  <Link to="" className="big">Home</Link>
                </LinkContainer>
              </div>
              <div className="list">
                <header className="big">Family</header>
                <ul className="ul1">
                  <li>
                    <LinkContainer to="/Ian">
                      <Link to="">Ian</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/Jan">
                      <Link to="">Jan</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/Fay">
                      <Link to="">Fay</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/Daniel">
                      <Link to="">Daniel</Link>
                    </LinkContainer>
                  </li>
                </ul>
              </div>
              <div className="list">
                <header className="big">Wedding</header>
                <ul className="ul2">
                  <li>
                    <LinkContainer to="/Before">
                      <Link to="">Before</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/During">
                      <Link to="">During</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/Families">
                      <Link to="">Families</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/Groups">
                      <Link to="">Groups</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/Reception">
                      <Link to="">Reception</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/Extras">
                      <Link to="">Extras</Link>
                    </LinkContainer>
                  </li>
                </ul>
              </div>
              <div className="list">
                <header className="big">Holidays</header>
                <ul className="ul2">
                  <li>
                    <LinkContainer to="/Barbados2005">
                      <Link to="">Barbados 2005</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/Cyprus2007">
                      <Link to="">Cyprus 2007</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/Cyprus2011">
                      <Link to="">Cyprus 2011</Link>
                    </LinkContainer>
                  </li>
                </ul>
              </div>
              <div className="list">
                <header className="big">Aircraft</header>
                <ul className="ul2">
                  <li>
                    <LinkContainer to="/Tornado">
                      <Link to="">Tornado</Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/OtherAircraft">
                      <Link to="">Other</Link>
                    </LinkContainer>
                  </li>
                </ul>
              </div>
              <div>
                <LinkContainer to="/Others">
                  <Link to="" className="big">Other</Link>
                </LinkContainer>
              </div>
              {
                props.auth.uid ?
                  <div className="list">
                    <header className="big">Secure</header>
                    <ul className="ul2">
                      <li>
                        <LinkContainer to="/Games">
                          <Link to="">Games</Link>
                        </LinkContainer>
                      </li>
                      <li>
                        <LinkContainer to="/LowFodmapDetails">
                          <Link to="">Low Fodmap Details</Link>
                        </LinkContainer>
                      </li>
                      <li>
                        <LinkContainer to="/Development">
                          <Link to="">Development</Link>
                        </LinkContainer>
                      </li>
                      <li>
                        <LinkContainer to="/CodeExplorer">
                          <Link to="">CodeExplorer</Link>
                        </LinkContainer>
                      </li>
                    </ul>
                  </div>
                  : ""}
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
}

function mapStateToProps(state) {
  const { auth, profile, authError } = state.firebase;
  return { auth, profile, authError };
};

const actionCreators = {
  login: authActions.login,
  logout: authActions.logout
};

export default connect(mapStateToProps, actionCreators)(NavBar);