import React, { Component } from 'react';

class Body extends Component {
	render() {
		return (
			<div id='body'>
				{this.props.content}
			</div>
		);
	}
}

export default Body;
