import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';
import imgSrc from '../../../images/Bingo.gif';

function ImagePopup(props) {    
    return (<Fragment>
        <Modal backdrop="static" show={props.show} onHide={props.onHide} delay={3000} id="imagePopup">
            <Modal.Body>
                <img src={imgSrc} alt="Let's play Bingo" />
            </Modal.Body>
        </Modal>
    </Fragment>
    );
}

export default ImagePopup;