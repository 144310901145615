import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/button';

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  },
    [playing, audio]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};

const Player = ({ url, start, timerStarted }) => {
  const [, toggle] = useAudio(url);
  const [startEnabled, toggleStartEnabled] = useState(true);
  const [gameRunning, setGameRunning] = useState(false);

  function toggleGame() {
    setGameRunning(!gameRunning);
    toggleStartEnabled(!startEnabled);
    toggle();
  }
  
  function startGame() {
    toggleGame();
    start();
  }

  return (<Button className="startCountdown" variant="success" disabled={timerStarted} onClick={() => startGame()}>
        Start
        </Button>
  );
};

export default Player;