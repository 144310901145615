import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { authentication } from './authReducer';
import { alert } from './alert';
import { bingo } from './bingo';

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    authentication,
    alert,
    bingo
});

export default rootReducer;