import React from 'react';
import BasePage from '../basePage';
import Barbados1 from '../../images/pictures/holidays/barbados05/p2170007_disp.jpg';
import Barbados2 from '../../images/pictures/holidays/barbados05/p2180012_disp.jpg';
import Barbados3 from '../../images/pictures/holidays/barbados05/p2180014_disp.jpg';
import Barbados4 from '../../images/pictures/holidays/barbados05/p2180023_disp.jpg';
import Barbados5 from '../../images/pictures/holidays/barbados05/p2180027_disp.jpg';
import Barbados6 from '../../images/pictures/holidays/barbados05/p2220051_disp.jpg';
import Barbados7 from '../../images/pictures/holidays/barbados05/p2220055_disp.jpg';
import Barbados8 from '../../images/pictures/holidays/barbados05/p2220084_disp.jpg';
import Barbados9 from '../../images/pictures/holidays/barbados05/p2220085_disp.jpg';
import Barbados10 from '../../images/pictures/holidays/barbados05/p2220090_disp.jpg';
import Barbados11 from '../../images/pictures/holidays/barbados05/p2220092_disp.jpg';
import Barbados12 from '../../images/pictures/holidays/barbados05/p2220094_disp.jpg';
import Barbados13 from '../../images/pictures/holidays/barbados05/p2220096_disp.jpg';
import Barbados14 from '../../images/pictures/holidays/barbados05/p2220098_disp.jpg';
import Barbados15 from '../../images/pictures/holidays/barbados05/p2230110_disp.jpg';
import Barbados16 from '../../images/pictures/holidays/barbados05/p3010004_disp.jpg';

const images = [
  Barbados1, Barbados2, Barbados3, Barbados4, Barbados5, Barbados6, Barbados7, Barbados8,
  Barbados9, Barbados10, Barbados11, Barbados12, Barbados13, Barbados14, Barbados15, Barbados16
];

function Barbados2005(props) {
  return <BasePage {...props} images={images} />
}

export default Barbados2005;