import React from 'react';
import BasePage from './basePage';
import Others1 from '../images/pictures/others/p00041_disp.jpg';
import Others2 from '../images/pictures/others/p00044_disp.jpg';
import Others3 from '../images/pictures/others/p00179_disp.jpg';
import Others4 from '../images/pictures/others/p00185_disp.jpg';
import Others5 from '../images/pictures/others/p00188_disp.jpg';
import Others6 from '../images/pictures/others/p00193_disp.jpg';
import Others7 from '../images/pictures/others/p0037_disp.jpg';
import Others8 from '../images/pictures/others/p0040_disp.jpg';
import Others9 from '../images/pictures/others/p5240066_disp.jpg';

const images = [
  Others1, Others2, Others3, Others4, Others5, Others6,
  Others7, Others8, Others9
];

function Others(props) {
  return <BasePage {...props} images={images} />
}

export default Others;