import { history } from '../helpers';
import { userConstants } from '../constants';
import { getFirebase } from 'react-redux-firebase';

export const authActions = {
    login,
    logout
};

function login(username, password) {
    return (dispatch, getState, { getFirebase }) => {
        dispatch(request({ username, password }));
        const firebase = getFirebase();
        firebase.login({
            email: username,
            password: password
        }).then(
            user => {
                dispatch(success(user));
                history.push('/');
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    const firebase = getFirebase();
    firebase.logout();
    history.push('/');
    return { type: userConstants.LOGOUT };
}