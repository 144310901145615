import React, { Component } from 'react';
import Progress from '../images/progress.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { authActions } from '../actions';

function validate(email, password) {
  // true means invalid, so our conditions got reversed
  return {
    email: email.length === 0, //true if email is empty
    password: password.length === 0 //true if password is empty
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',

      touched: {
        email: false,
        password: false
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  handleChange = (evt) => {
    this.setState({ [evt.target.id]: evt.target.value });
  }

  handleBlur = (field) => () => {
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (!this.canBeSubmitted()) {
      return;
    }

    var progressImg = document.getElementById('imgLoggingIn');
    if (progressImg) {
      progressImg.style.display = 'inherit';
    }

    this.props.login(this.state.email, this.state.password);
  }

  canBeSubmitted() {
    const errors = validate(this.state.email, this.state.password);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    return !isDisabled;
  }

  render() {
    const { authError } = this.props;
    if (authError) {
      var img = document.getElementById('imgLoggingIn');
      if (img) {
        img.style.display = 'none';
      }
    }

    const errors = validate(this.state.email, this.state.password);
    const isDisabled = Object.keys(errors).some(x => errors[x]);

    const shouldMarkError = (field) => {
      const hasError = errors[field];
      const shouldShow = this.state.touched[field];
      return hasError ? shouldShow : false;
    };
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="loginForm" onSubmit={this.handleSubmit}>
            <div id="loginPanel">
              <fieldset>
                <div className="login_form_row">
                  <span>
                    <label className='icon'>
                      <FontAwesomeIcon icon="user" />
                    </label>
                    <input id="email" placeholder="Email"
                      title="Enter your Email..." type="text"
                      className={shouldMarkError('email') ? "error" : ""}
                      value={this.state.email}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur('email')} />
                    <span className={shouldMarkError('email') ? "error" : "hidden"}>*</span>
                  </span>
                </div>
                <div className="login_form_row">
                  <span>
                    <label className='icon'>
                      <FontAwesomeIcon icon="key" />
                    </label>
                    <input id="password" placeholder="Password"
                      title="Enter your Password..." type="password"
                      className={shouldMarkError('password') ? "error" : ""}
                      value={this.state.password}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur('password')} />
                    <span className={shouldMarkError('password') ? "error" : "hidden"}>*</span>
                  </span>
                </div>

                {
                  <div className="login_form_row">
                    <button type="submit" className='btn btn-primary' disabled={isDisabled}>Login</button>
                  </div>
                }

                <span>
                  <img id="imgLoggingIn" alt="Loading..." style={{ display: 'none' }}
                    src={Progress} title="Authenticating details..." />
                </span>

                {!authError
                  ? ""
                  : <div className="login_form_row">
                    <span id="lblLoginError" className="small" style={{ color: "Red", fontWeight: "bold" }}>{authError.message}</span>
                  </div>
                }
              </fieldset>
              <br />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: authActions.login,
  logout: authActions.logout
};

export default connect(mapStateToProps, actionCreators)(Login);