import React from 'react';
import BasePage from '../basePage';
import Fay1 from '../../images/pictures/fay/p1250015_disp.jpg';
import Fay2 from '../../images/pictures/fay/p2090040_disp.jpg';
import Fay3 from '../../images/pictures/fay/p3010032_disp.jpg';
import Fay4 from '../../images/pictures/fay/p3160042_disp.jpg';
import Fay5 from '../../images/pictures/fay/p7210022_disp.jpg';
import Fay6 from '../../images/pictures/fay/p8140030_disp.jpg';
import Fay7 from '../../images/pictures/fay/pa090065_disp.jpg';
import Fay8 from '../../images/pictures/fay/pa140009_disp.jpg';
import Fay9 from '../../images/pictures/fay/pa310007_disp.jpg';

const images = [
  Fay1, Fay2, Fay3, Fay4, Fay5, Fay6, Fay7, Fay8, Fay9
];

function Fay(props) {
  return <BasePage {...props} images={images} />
}

export default Fay;