import React from 'react';
import BasePage from '../basePage';
import Before1 from '../../images/pictures/wedding/before/fay1_disp.jpg';
import Before2 from '../../images/pictures/wedding/before/fay2_disp.jpg';
import Before3 from '../../images/pictures/wedding/before/fay3_disp.jpg';
import Before4 from '../../images/pictures/wedding/before/fayanddad1_disp.jpg';
import Before5 from '../../images/pictures/wedding/before/fayanddad2_disp.jpg';
import Before6 from '../../images/pictures/wedding/before/fayanddad3_disp.jpg';
import Before7 from '../../images/pictures/wedding/before/fayanddad4_disp.jpg';
import Before8 from '../../images/pictures/wedding/before/ianandjohn1_disp.jpg';
import Before9 from '../../images/pictures/wedding/before/ianandjohn2_disp.jpg';
import Before10 from '../../images/pictures/wedding/before/ianandjohn3_disp.jpg';
import Before11 from '../../images/pictures/wedding/before/ianandjohn4_disp.jpg';
import Before12 from '../../images/pictures/wedding/before/jan1_disp.jpg';
import Before13 from '../../images/pictures/wedding/before/janandjayne1_disp.jpg';
import Before14 from '../../images/pictures/wedding/before/janandjayne2_disp.jpg';
import Before15 from '../../images/pictures/wedding/before/janandjayne3_disp.jpg';
import Before16 from '../../images/pictures/wedding/before/janandjayne4_disp.jpg';
import Before17 from '../../images/pictures/wedding/before/janandjayne5_disp.jpg';
import Before18 from '../../images/pictures/wedding/before/janandjayne6_disp.jpg';
import Before19 from '../../images/pictures/wedding/before/janandrachel1_disp.jpg';
import Before20 from '../../images/pictures/wedding/before/jangettingready1_disp.jpg';
import Before21 from '../../images/pictures/wedding/before/jangettingready2_disp.jpg';
import Before22 from '../../images/pictures/wedding/before/jangettingready3_disp.jpg';
import Before23 from '../../images/pictures/wedding/before/jangettingready4_disp.jpg';
import Before24 from '../../images/pictures/wedding/before/jayne1_disp.jpg';
import Before25 from '../../images/pictures/wedding/before/jaynegettingready_disp.jpg';

const images = [
    Before1, Before2, Before3, Before4, Before5, Before6, Before7, Before8,
    Before9, Before10, Before11, Before12, Before13, Before14, Before15,
    Before16, Before17, Before18, Before19, Before20, Before21,
    Before22, Before23, Before24, Before25
];

function Before(props) {
    return <BasePage {...props} images={images} />
}

export default Before;