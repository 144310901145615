import React from 'react';
import BasePage from '../basePage';
import Jan1 from '../../images/pictures/jan/p2040029_disp.jpg';
import Jan2 from '../../images/pictures/jan/p3160040_disp.jpg';
import Jan3 from '../../images/pictures/jan/p7210015_disp.jpg';
import Jan4 from '../../images/pictures/jan/p8140027_disp.jpg';
import Jan5 from '../../images/pictures/jan/p8190002_disp.jpg';
import Jan6 from '../../images/pictures/jan/pa230021_disp.jpg';

const images = [
  Jan1, Jan2, Jan3, Jan4, Jan5, Jan6
];

function Jan(props) {
  return <BasePage {...props} images={images} />
}

export default Jan;