import React from 'react';
var FontAwesome = require('react-fontawesome');

function CorrectAnswer(props) {
    var colour = props.correctAnswer ? "#2CC227" : "red"
    return <div className="correctAnswer" style={{ backgroundColor: colour }}
        onClick={props.disabled ? null : () => props.setSpecificAnswer()}
        title={props.correctAnswer ? "Correct Answer" : "Wrong Answer"}>

        <FontAwesome className="super-crazy-colors" name={props.correctAnswer ? "check-circle" : "times-circle"} size="2x"
            style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }} />
    </div>
}

export { CorrectAnswer };