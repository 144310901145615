import React, { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/button';
import Form from 'react-bootstrap/form';
import { functions } from '../../../helpers';

function Letters(props) {
    // Ratios taken from http://www.thecountdownpage.com/letters.htm
    const [consonantArray, setConsonantArray] = useState(null);
    const [vowelArray, setVowelArray] = useState(null);

    useEffect(() => {
        const consonants = ["B", "B", "C", "C", "C", "D", "D", "D", "D", "D", "D",
        "F", "F", "G", "G", "G", "H", "H", "J", "K", "L", "L", "L", "L", "L",
        "M", "M", "M", "M", "N", "N", "N", "N", "N", "N", "N", "N",
        "P", "P", "P", "P", "Q", "R", "R", "R", "R", "R", "R", "R", "R", "R",
        "S", "S", "S", "S", "S", "S", "S", "S", "S",
        "T", "T", "T", "T", "T", "T", "T", "T", "T",
        "V", "W", "X", "Y", "Z"];
        if (consonantArray === null) {
            setConsonantArray(functions.shuffle(consonants));
        }
    },
        [consonantArray]
    );

    useEffect(() => {
        const vowels = ["A", "A", "A", "A", "A", "A", "A", "A", "A", "A",
        "A", "A", "A", "A", "A", "E", "E", "E", "E", "E", "E",
        "E", "E", "E", "E", "E", "E", "E", "E", "E", "E", "E",
        "E", "E", "E", "E", "I", "I", "I", "I", "I", "I", "I",
        "I", "I", "I", "I", "I", "I", "O", "O", "O", "O", "O",
        "O", "O", "O", "O", "O", "O", "O", "O", "U", "U", "U", "U", "U"];
        if (vowelArray === null) {
            setVowelArray(functions.shuffle(vowels));
        }
    },
        [vowelArray]
    );

    const [letterIndex, setLetterIndex] = useState(0);
    const [letter1, setLetter1] = useState(null);
    const [letter2, setLetter2] = useState(null);
    const [letter3, setLetter3] = useState(null);
    const [letter4, setLetter4] = useState(null);
    const [letter5, setLetter5] = useState(null);
    const [letter6, setLetter6] = useState(null);
    const [letter7, setLetter7] = useState(null);
    const [letter8, setLetter8] = useState(null);
    const [letter9, setLetter9] = useState(null);

    function generateConsonant() {
        const min = 0;
        const max = consonantArray.length;
        const rand = min + Math.random() * (max - min);
        var index = Math.floor(rand);
        setLetter(consonantArray[index]);
        consonantArray.splice(index, 1);
        setConsonantArray(consonantArray);
    }

    function generateVowel() {
        const min = 0;
        const max = vowelArray.length;
        const rand = min + Math.random() * (max - min);
        var index = Math.floor(rand);
        setLetter(vowelArray[index]);
        vowelArray.splice(index, 1);
        setVowelArray(vowelArray);
    }

    function setLetter(letter) {
        switch (letterIndex) {
            case 1:
                setLetter2(letter);
                setLetterIndex(2);
                break;
            case 2:
                setLetter3(letter);
                setLetterIndex(3);
                break;
            case 3:
                setLetter4(letter);
                setLetterIndex(4);
                break;
            case 4:
                setLetter5(letter);
                setLetterIndex(5);
                break;
            case 5:
                setLetter6(letter);
                setLetterIndex(6);
                break;
            case 6:
                setLetter7(letter);
                setLetterIndex(7);
                break;
            case 7:
                setLetter8(letter);
                setLetterIndex(8);
                break;
            case 8:
                setLetter9(letter);
                setLetterIndex(9);
                break;
            default:
                setLetter1(letter);
                setLetterIndex(1);
                break;
        }
    }

    function clear() {
        setLetterIndex(0);
        setLetter1("");
        setLetter2("");
        setLetter3("");
        setLetter4("");
        setLetter5("");
        setLetter6("");
        setLetter7("");
        setLetter8("");
        setLetter9("");
    }

    return <Fragment>
        <div style={{ border: '1px solid black' }}>
            <h1>Letters</h1>
        </div>
        <div className="game">
            <Button className="startCountdown" variant="info" onClick={() => generateConsonant()} disabled={props.timerActive}>
                Consonant
            </Button>
            &nbsp;
            <Button className="startCountdown" variant="info" onClick={() => generateVowel()} disabled={props.timerActive}>
                Vowel
            </Button>
            <Form.Group className="countdown-group-block">
                <Form.Control size="sm" type="text" className="countdown-box" disabled value={letter1 || ''} />
                <Form.Control size="sm" type="text" className="countdown-box" disabled value={letter2 || ''} />
                <Form.Control size="sm" type="text" className="countdown-box" disabled value={letter3 || ''} />
                <Form.Control size="sm" type="text" className="countdown-box" disabled value={letter4 || ''} />
                <Form.Control size="sm" type="text" className="countdown-box" disabled value={letter5 || ''} />
                <Form.Control size="sm" type="text" className="countdown-box" disabled value={letter6 || ''} />
                <Form.Control size="sm" type="text" className="countdown-box" disabled value={letter7 || ''} />
                <Form.Control size="sm" type="text" className="countdown-box" disabled value={letter8 || ''} />
                <Form.Control size="sm" type="text" className="countdown-box" disabled value={letter9 || ''} />
            </Form.Group>

            <Button className="startCountdown" variant="info" onClick={() => clear()} disabled={props.timerActive}>
                Clear
            </Button>
        </div>
    </Fragment>
}

export { Letters };