import React from 'react';
import BasePage from '../basePage';
import Extras1 from '../../images/pictures/wedding/extras/reception/cakecutting1_disp.jpg';
import Extras2 from '../../images/pictures/wedding/extras/reception/cakecutting2_disp.jpg';
import Extras3 from '../../images/pictures/wedding/extras/reception/cakecutting3_disp.jpg';
import Extras4 from '../../images/pictures/wedding/extras/reception/cakecutting4_disp.jpg';
import Extras5 from '../../images/pictures/wedding/extras/reception/dinner1_disp.jpg';
import Extras6 from '../../images/pictures/wedding/extras/reception/dinner2_disp.jpg';
import Extras7 from '../../images/pictures/wedding/extras/reception/sittingdowntodinner_disp.jpg';
import Extras8 from '../../images/pictures/wedding/extras/reception/table1_disp.jpg';
import Extras9 from '../../images/pictures/wedding/extras/reception/table2_disp.jpg';
import Extras10 from '../../images/pictures/wedding/extras/reception/table3_disp.jpg';
import Extras11 from '../../images/pictures/wedding/extras/reception/table4_disp.jpg';
import Extras12 from '../../images/pictures/wedding/extras/reception/tablelayouts_disp.jpg';

const images = [
  Extras1, Extras2, Extras3, Extras4, Extras5, Extras6, Extras7, Extras8,
  Extras9, Extras10, Extras11, Extras12
];

function Reception(props) {
  return <BasePage {...props} images={images} />
}

export default Reception;