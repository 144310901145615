import { bingoConstants } from '../constants';
import { history } from '../helpers';

export const bingoActions = {
    storeNumber,
    retrieveAll,
    clear
};

function storeNumber(number) {
    return dispatch => {
        dispatch(success(number));
    };

    function success(number) { return { type: bingoConstants.STORENUMBER_SUCCESS, number } }
}

function retrieveAll() {
    return dispatch => {
        dispatch(success());
    };

    function success() { return { type: bingoConstants.RETRIEVEALLNUMBERS_SUCCESS } }
}

function clear() {
    history.push('/#/bingo');
    return { type: bingoConstants.CLEARNUMBERS_SUCCESS };
}