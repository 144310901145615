import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/button';
import { Link } from 'react-router-dom';

function BingoSplash() {
    return (
        <Carousel.Caption>
            <Link to="bingo">
                <Button variant="dark">Play Bingo</Button>
            </Link>
        </Carousel.Caption>
    );
}

export { BingoSplash };