import React from 'react';
//import { alertActions, userActions } from '../actions';
import { connect } from 'react-redux';
import { QuizSplash } from '../Quiz';
import { BingoSplash } from '../Bingo/BingoSplash';
import { CountdownSplash } from '../Countdown/CountdownSplash';
import Carousel from 'react-bootstrap/Carousel';
import QuizImage from '../../../images/quiz.jpg';
import BingoImage from '../../../images/bingo.jpg';
import CountdownImage from '../../../images/countdown.png';

function Games() {
    return <Carousel>
        <Carousel.Item>
            <img
                className="d-block w-100"
                src={QuizImage}
                alt="Quiz"
            />
            <QuizSplash />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100"
                src={BingoImage}
                alt="Bingo"
            />
            <BingoSplash />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100"
                src={CountdownImage}
                alt="Countdown"
            />
            <CountdownSplash />
        </Carousel.Item>
    </Carousel>
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    // clearAlerts: alertActions.clear,
    // logout: userActions.logout
};

const connectedGames = connect(mapState, actionCreators)(Games);
export { connectedGames as Games };