import { bingoConstants } from '../constants';
import { settings } from '../settings.js';

function generateRandomNumber() {
    const min = 1;
    const max = settings.maxBalls + 1;
    const rand = min + Math.random() * (max - min);
    return Math.floor(rand);
}

export function bingo(state = {}, action) {
    switch (action.type) {
        case bingoConstants.STORENUMBER_SUCCESS:
            var bingonumbers = [];
            bingonumbers = JSON.parse(localStorage.getItem('bingonumbers')) || [];
            var numberAvailable = false;
            var number;
            while (!numberAvailable) {
                if (number !== undefined && bingonumbers.indexOf(number) === -1) {
                    numberAvailable = true;                    
                } else {
                    number = generateRandomNumber();
                }
            }

            if (numberAvailable) {
                bingonumbers.push(number);
                localStorage.setItem('bingonumbers', JSON.stringify(bingonumbers));
            }

            return {
                bingonumbers
            };
        case bingoConstants.RETRIEVEALLNUMBERS_SUCCESS:
            return {
                bingonumbers: JSON.parse(localStorage.getItem('bingonumbers')) || []
            };
        case bingoConstants.CLEARNUMBERS_SUCCESS:
            localStorage.removeItem('bingonumbers');
            return {};
        default:
            return state
    }
}