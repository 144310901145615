import React from 'react';
import BasePage from '../basePage';
import FamiliesAfter1 from '../../images/pictures/wedding/after/family/iansfamily1_disp.jpg';
import FamiliesAfter2 from '../../images/pictures/wedding/after/family/iansfamily2_disp.jpg';
import FamiliesAfter3 from '../../images/pictures/wedding/after/family/iansfamily3_disp.jpg';
import FamiliesAfter4 from '../../images/pictures/wedding/after/family/iansfamily4_disp.jpg';
import FamiliesAfter5 from '../../images/pictures/wedding/after/family/jansfamily1_disp.jpg';
import FamiliesAfter6 from '../../images/pictures/wedding/after/family/jansfamily2_disp.jpg';
import FamiliesAfter7 from '../../images/pictures/wedding/after/family/jansfamily3_disp.jpg';
import FamiliesAfter8 from '../../images/pictures/wedding/after/family/jansfamily4_disp.jpg';
import FamiliesAfter9 from '../../images/pictures/wedding/after/family/usandiansfamily1_disp.jpg';
import FamiliesAfter10 from '../../images/pictures/wedding/after/family/usandiansfamily2_disp.jpg';

const images = [
	FamiliesAfter1, FamiliesAfter2, FamiliesAfter3, FamiliesAfter4, FamiliesAfter5,
	FamiliesAfter6, FamiliesAfter7, FamiliesAfter8, FamiliesAfter9, FamiliesAfter10
];

function FamiliesAfter(props) {
	return <BasePage {...props} images={images} />
  }
  
  export default FamiliesAfter;