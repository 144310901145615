export const firebase = {
    apiKey: "AIzaSyA3XVS2a9JoNi7g2dVY1V6x9OQmlfjfMjw",
    authDomain: "crozweb-374a8.firebaseapp.com",
    databaseURL: "https://crozweb-374a8.firebaseio.com",
    projectId: "crozweb-374a8",
    storageBucket: "crozweb-374a8.appspot.com",
    messagingSenderId: "55918643320"
}

export const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
    enableLogging: false
}