import React from 'react';
import BasePage from '../basePage';
import Tornado1 from '../../images/pictures/aircraft/tornado/a_disp.jpg';
import Tornado2 from '../../images/pictures/aircraft/tornado/b_disp.jpg';
import Tornado3 from '../../images/pictures/aircraft/tornado/c_disp.jpg';
import Tornado4 from '../../images/pictures/aircraft/tornado/d_disp.jpg';
import Tornado5 from '../../images/pictures/aircraft/tornado/e_disp.jpg';
import Tornado6 from '../../images/pictures/aircraft/tornado/f_disp.jpg';
import Tornado7 from '../../images/pictures/aircraft/tornado/g_disp.jpg';
import Tornado8 from '../../images/pictures/aircraft/tornado/h_disp.jpg';
import Tornado9 from '../../images/pictures/aircraft/tornado/i_disp.jpg';
import Tornado10 from '../../images/pictures/aircraft/tornado/j_disp.jpg';
import Tornado11 from '../../images/pictures/aircraft/tornado/k_disp.jpg';
import Tornado12 from '../../images/pictures/aircraft/tornado/l_disp.jpg';
import Tornado13 from '../../images/pictures/aircraft/tornado/m_disp.jpg';
import Tornado14 from '../../images/pictures/aircraft/tornado/n_disp.jpg';
import Tornado15 from '../../images/pictures/aircraft/tornado/o_disp.jpg';
import Tornado16 from '../../images/pictures/aircraft/tornado/p_disp.jpg';
import Tornado17 from '../../images/pictures/aircraft/tornado/q_disp.jpg';
import Tornado18 from '../../images/pictures/aircraft/tornado/r_disp.jpg';
import Tornado19 from '../../images/pictures/aircraft/tornado/s_disp.jpg';

const images = [
  Tornado1, Tornado2, Tornado3, Tornado4, Tornado5, Tornado6, Tornado7, Tornado8,
  Tornado9, Tornado10, Tornado11, Tornado12, Tornado13, Tornado14,
  Tornado15, Tornado16, Tornado17, Tornado18, Tornado19
];

function Tornado(props) {
  return <BasePage {...props} images={images} />
}

export default Tornado;