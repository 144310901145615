import React from 'react';
import BasePage from '../basePage';
import GroupsAfter1 from '../../images/pictures/wedding/after/groups/bothfamilies1_disp.jpg';
import GroupsAfter2 from '../../images/pictures/wedding/after/groups/bothfamilies2_disp.jpg';
import GroupsAfter3 from '../../images/pictures/wedding/after/groups/confetti1_disp.jpg';
import GroupsAfter4 from '../../images/pictures/wedding/after/groups/confetti2_disp.jpg';
import GroupsAfter5 from '../../images/pictures/wedding/after/groups/confetti3_disp.jpg';
import GroupsAfter6 from '../../images/pictures/wedding/after/groups/confetti4_disp.jpg';
import GroupsAfter7 from '../../images/pictures/wedding/after/groups/friends1_disp.jpg';
import GroupsAfter8 from '../../images/pictures/wedding/after/groups/friends2_disp.jpg';
import GroupsAfter9 from '../../images/pictures/wedding/after/groups/ian1_disp.jpg';
import GroupsAfter10 from '../../images/pictures/wedding/after/groups/ian2_disp.jpg';
import GroupsAfter11 from '../../images/pictures/wedding/after/groups/jansfamily5_disp.jpg';
import GroupsAfter12 from '../../images/pictures/wedding/after/groups/jansfamily6_disp.jpg';
import GroupsAfter13 from '../../images/pictures/wedding/after/groups/outside1_disp.jpg';
import GroupsAfter14 from '../../images/pictures/wedding/after/groups/thecrowd1_disp.jpg';
import GroupsAfter15 from '../../images/pictures/wedding/after/groups/thecrowd2_disp.jpg';

const images = [
  GroupsAfter1, GroupsAfter2, GroupsAfter3, GroupsAfter4, GroupsAfter5, GroupsAfter6,
  GroupsAfter7, GroupsAfter8, GroupsAfter9, GroupsAfter10, GroupsAfter11, GroupsAfter12,
  GroupsAfter13, GroupsAfter14, GroupsAfter15
];

function GroupsAfter(props) {
	return <BasePage {...props} images={images} />
  }
  
  export default GroupsAfter;