import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import App from './App';
import Home from './components/Home';
import Ian from './components/family/ian';
import Jan from './components/family/jan';
import Fay from './components/family/fay';
import Daniel from './components/family/daniel';
import Before from './components/wedding/before';
import During from './components/wedding/during';
import Families from './components/wedding/familiesAfter';
import GroupsAfter from './components/wedding/groupsAfter';
import Reception from './components/wedding/reception';
import Extras from './components/wedding/extras';
import Barbados2005 from './components/holidays/barbados2005';
import Cyprus2007 from './components/holidays/cyprus2007';
import Cyprus2011 from './components/holidays/cyprus2011';
import Others from './components/others';
import Development from './components/development';
import CodeExplorer from './components/codeExplorer';
import Tornado from './components/aircraft/tornado';
import OtherAircraft from './components/aircraft/otherAircraft';
import PageNotFound from './components/pageNotFound';
import LowFodmapDetails from './components/diet/lowFodmapDetails';
import { connect } from 'react-redux';
import { Games } from './components/games/home/games';
import { Bingo } from './components/games/Bingo';
import { Countdown } from './components/games/Countdown';
import { Quiz } from './components/games/Quiz';

function AuthedRoute({ component: Component, authed, ...rest }) {
  return (
    <Route {...rest} render={(props) => authed.auth.uid ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />} />
  );
}

// eslint-disable-next-line react/no-multi-comp
const Routes = (props) =>
  <App>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/Home" component={Home} />
      <Route path="/Ian" render={(props) => <Ian content="Ian" {...props} />} />
      <Route path="/Jan" render={(props) => <Jan content="Jan" {...props} />} />
      <Route path="/Fay" render={(props) => <Fay content="Fay" {...props} />} />
      <Route path="/Daniel" render={(props) => <Daniel content="Daniel" {...props} />} />
      <Route path="/Before" render={(props) => <Before content="Before" {...props} />} />
      <Route path="/During" render={(props) => <During content="During" {...props} />} />
      <Route path="/Families" render={(props) => <Families content="Families" {...props} />} />
      <Route path="/GroupsAfter" render={(props) => <GroupsAfter content="Groups After" {...props} />} />
      <Route path="/Reception" render={(props) => <Reception content="Reception" {...props} />} />
      <Route path="/Extras" render={(props) => <Extras content="Extras" {...props} />} />
      <Route path="/Barbados2005" render={(props) => <Barbados2005 content="Barbados 2005" {...props} />} />
      <Route path="/Cyprus2007" render={(props) => <Cyprus2007 content="Cyprus 2007" {...props} />} />
      <Route path="/Cyprus2011" render={(props) => <Cyprus2011 content="Cyprus 2011" {...props} />} />
      <Route path="/Others" render={(props) => <Others content="Various" {...props} />} />
      <Route path="/Tornado" render={(props) => <Tornado content="Tornado Tribute" {...props} />} />
      <Route path="/OtherAircraft" render={(props) => <OtherAircraft content="Other Jets" {...props} />} />
      <AuthedRoute authed={props} path='/Development' component={Development} />
      <AuthedRoute authed={props} path='/CodeExplorer' component={CodeExplorer} />
      <AuthedRoute authed={props} path='/LowFodmapDetails' component={LowFodmapDetails} />
      <AuthedRoute authed={props} exact path="/Games" component={Games} />
      <AuthedRoute authed={props} path="/bingo" component={Bingo} />
      <AuthedRoute authed={props} path="/countdown" component={Countdown} />
      <AuthedRoute authed={props} path="/quiz" component={Quiz} />
      <Route path="/*" component={PageNotFound} />
    </Switch>
  </App>;

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    authError: state.firebase.auth.authError
  };
};

export default connect(mapStateToProps, null)(Routes);