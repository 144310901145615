import React from 'react';
import { CorrectAnswer } from './CorrectAnswer';

function AnswerBox(props) {

    return <div className="answerDiv">
        <div className="answerBox" style={{ backgroundColor: props.backgroundColor, display: "inlineBlock" }} onClick={props.onClick}
        title="Enter answer">
            <p>{props.answer}</p>
        </div>
        <CorrectAnswer disabled={props.answer === null || props.answer.length === 0 } correctAnswer={props.correctAnswer} 
            style={{ display: "inlineBlock" }} setSpecificAnswer={() => props.setSpecificAnswer()} answerIndex={props.answerIndex} />
    </div>
}

export { AnswerBox };