import React from 'react';
import BasePage from '../basePage';
import Ian1 from '../../images/pictures/ian/p7210019_disp.jpg';
import Ian2 from '../../images/pictures/ian/p7210020_disp.jpg';

const images = [
  Ian1, Ian2
];

function Ian(props) {
  return <BasePage {...props} images={images} />
}

export default Ian;